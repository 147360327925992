<template>
    <div>
        <button v-if="false" @click="fetchChartData">Load</button>
        <h3 class="heading3-chart">Top Investors</h3>
        <div class="table-result" id="result">

             <table id="table-sparkline" v-if="loaded">
                <thead>
                    <tr>
                        <th>
                            Investor
                        </th>
                        <th>
                             Companies
                        </th>
                        <th>
                            Rounds
                        </th>
                        <th v-for="stage in stages" :key="stage">
                            {{stage}}
                        </th>
                        <th v-if="false">
                            By Stage
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="investor in investors" :key="investor.id">
                        <td>
                            <a v-if="investor.ecosystem_id" :href="'/ecosystems/' + investor.ecosystem_id" target="_blank">{{investor.name}}</a>
                            <span v-else>{{investor.name}}</span>
                        </td>
                        <td>
                            <a class="link" @click="() => {openStartupsModal(investor.id)}">
                                {{investor.startups_count}}
                            </a>
                        </td>
                        <td>
                            {{investor.rounds_count}}
                        </td>
                        <td v-for="stage in investor.stages" :key="stage.stage" style="vertical-align: bottom">
                            <div :id="'investor-'+investor.id+'-stage-'+stage.stage" style="width: 20px; margin: auto" :style="{
                                backgroundColor: stage.color,
                                height: (stage.y / investor.max_value * 90) + '%',
                            }"

                            @click="() => {if (stage.count) openStageStartupsModal([investor.id, stage.stage])}">
                            </div>

                            <b-tooltip :target="'investor-'+investor.id+'-stage-'+stage.stage" placement="right" variant="light">
                                <div v-html="stage.label" style="font-size: 12px"></div>
                            </b-tooltip>
                        </td>

                        <td v-if="false">
                            <RaisedByStageColumnChart
                                :investor-id="investor.id"
                                :chart-data="investor.stages"
                                :max-value="investor.max_value"
                                :chartSettings="chartSettings"
                                @chartClicked="openStageStartupsModal"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-else class="d-flex justify-content-center">
                <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
            </div>
        </div>
    </div>

</template>

<script>
import dashboardChartMixin from "@/mixins/dashboardChartMixin";
import RaisedByStageColumnChart from "@/components/charts/private/mars/RaisedByStageColumnChart.vue";

    export default {
        components: {RaisedByStageColumnChart},

        data() {
            return {
                loaded: false,
                chartData: [],
                investors: [],

                stages: [
                    'Seed',
                    'Angel',
                    'Series A',
                    'Series B',
                    'Series C',
                    'Series D',
                    'Series E',
                    'Other',
                ]
            }
        },

        mixins: [dashboardChartMixin],

        methods: {
            fetchChartData() {
                this.getChartData('TopInvestors').then(response => {
                    this.investors = response.data;
                    this.loaded = true
                })
            },

            openStartupsModal(investorId) {
                this.$emit('showStartups', ['TopInvestors', `Startups`, investorId + ';'])
            },

            openStageStartupsModal([investorId, stage]) {
                this.$emit('showStartups', ['TopInvestors', `Startups by stage "${stage}"`, investorId + ';' + stage])
            }
        }
    }
</script>

<style>
.table-result {
    #table-sparkline {
        width:100%;
        td,
        th {
           height:40px;
        }
    }
}
</style>
